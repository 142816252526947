#index {
    #footer {
        margin-top: -1px;
    }
}
#footer {
    position: relative;
    color: $text-footer;
    font-size: 15px;
    padding-top: $margin-xl;
    @media (max-width: 767px) {
        margin-top: $margin-m !important;
        padding-top: 0;
    }
    @media (max-width: 575px) {
        font-size: 20px;
    }
    .footer-container {
        margin-top: 45px;
        background-color: $bg-footer;
        color: $text-footer;
        @media (max-width: 767px) {
            margin-top: 0;
        }
        > .container {
            > .row {
                display: flex;
                @media (max-width: 575px) {
                    margin-left: 0;
                    margin-right: 0;
                }
                // Modules greffés au hook hookDisplayFooter
                a {
                    color: $text-footer;
                    &:hover {
                        color: $primary-color;
                    }
                }
                .block-contact {
                    // Module ps_contactinfo
                    display: flex;
                    flex-grow: 3;
                    flex-shrink: 0;
                    @media (max-width: 767px) {
                        width: 100%;
                        margin-bottom: $margin-m;
                    }
                    #footer_store_info {
                        a {
                            &:hover {
                                i {
                                    margin-right: 10px;
                                }
                            }
                            i {
                                font-size: 24px;
                                margin-right: 5px;
                                vertical-align: bottom;
                                transition: all .3s ease-in;
                            }
                        }
                        @media (max-width: 991px) {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            .footer__title {
                                font-size: 26px;
                            }
                            address {
                                font-size: 18px;
                            }
                            .contact-info-container,
                            .contact-links {
                                flex: 1;
                            }
                        }
                        @media (max-width: 575px) {
                            flex-direction: column;
                        }
                    }
                    .logo-footer {
                        margin-right: $margin-l;
                        width: 165px;
                        @media (max-width: 991px) {
                            width: 100px;
                            margin-right: $margin-m;
                        }
                        @media (max-width: 767px) {
                            width: 215px;
                            margin-right: $margin-l;
                        }
                        @media (max-width: 575px) {
                            width: 195px;
                            margin-right: $margin-m;
                        }
                        @media (max-width: 399px) {
                            display: none;
                        }
                    }
                    .store-information {
                        text-align: left;
                        @media (max-width: 399px) {
                            width: 100%;
                            text-align: center;
                        }
                        address {
                            font-size: 15px;
                            line-height: 20px;
                            @media (max-width: 767px) {
                                font-size: 18px;
                                line-height: 24px;
                            }
                            @media (max-width: 575px) {
                                font-size: 20px;
                                line-height: 26px;
                            }
                        }
                    }
                    .social-sharing {
                        // Module ps_socialfollow
                        display: flex;
                        align-items: center;
                        width: 100%;
                        max-width: 100%;
                        margin: 10px -12px 0 -12px;
                        padding: 0;
                        @media (max-width: 399px) {
                            margin: 0;
                            justify-content: center;
                        }
                        .social-share-btn{
                            background-color: transparent;
                            background-size: cover;
                            margin: 0 12px !important;
                            &.facebook {
                                background-image: url(../img/webxy/facebook-black.svg);
                                width: 11px;
                                height: 22px;
                                order: 1;
                            }
                            &.twitter {
                                background-image: url(../img/webxy/twitter-black.svg);
                            }
                            &.linkedin {
                                background-image: url(../img/webxy/linkedin-black.svg);
                                width: 20px;
                                height: 20px;
                                order: 0;
                            }
                            &.pinterest {
                                background-image: url(../img/webxy/pinterest-black.svg);
                            }
                            &.rss {
                                background-image: url(../img/webxy/rss-black.svg);
                            }
                            &.youtube {
                                background-image: url(../img/webxy/youtube-black.svg);
                                width: 22px;
                                height: 16px;
                                order: 3;
                            }
                            &.vimeo{
                                background-image: url(../img/webxy/vimeo.svg);
                            }
                            &.instagram{
                                background-image: url(../img/webxy/instagram-black.svg);
                                width: 20px;
                                height: 20px;
                                order: 2;
                            }
                        }
                    }
                }
                .block_newsletter {
                    form {
                        input {
                            border-top-left-radius: $round-radius;
                            border-bottom-left-radius: $round-radius;
                            border-right-width: 0;
                            @media (max-width: 575px) {
                                height: 50px;
                            }
                        }
                        .input-group-append {
                            button {
                                border-width: 1px;
                                border-color: $dark-color;
                                border-left-width: 0;
                                border-top-right-radius: $round-radius;
                                border-bottom-right-radius: $round-radius;
                                background-color: $light-color;
                                transition: all .3s ease-in;
                                i {
                                    background: $primary-color;
                                    color: $light-color;
                                    padding: 7px;
                                    border-radius: $round-radius;
                                    transition: all .3s ease-in;
                                }
                                &:focus,
                                &:active {
                                    outline: none;
                                }
                                &:hover {
                                    i {
                                        background-color: $primary-color-alt;
                                    }
                                }
                            }
                        }
                        .newsletter-conditions {
                            a {
                                text-decoration: underline;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                        #gdpr_consent {
                            label {
                                color: $text-footer !important;
                            }
                            a {
                                text-decoration: underline;
                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
                .linklist-block {
                    // Module linkblock
                    flex-grow: 8;
                    @media (max-width: 575px) {
                        text-align: center;
                    }
                    ul[id^="footer_sub_menu"] {
                        display: flex;
                        flex-direction: column;
                    }
                    .link-cms-page-1-2 {
                        // Page Livraison
                        order: 1;
                    }
                    .link-cms-page-2-2 {
                        // Page Mentions Légales
                        order: 2;
                    }
                    .link-cms-page-4-2 {
                        // Page A propos
                        order: 0;
                    }
                    .link-cms-page-5-2 {
                        // Page Paiement sécurisé
                        order: 3;
                    }
                }
                #block_myaccount_infos {
                    // Module ps_customeraccountlinks
                    flex-grow: 1;
                }
            }
        }
    }
    .footer__title {
        color: $text-title-footer;
        font-weight: 500;
        font-size: 25px;
        line-height: 20px;
        margin-bottom: $margin-s;
        text-transform: unset;
        > i {
            font-size: 0.85em;
        }
        @media (max-width: $container-width-desktop-up) {
            font-size: 22px;
        }
        @media (max-width: 575px) {
            font-size: 24px;
            line-height: 36px;
            margin-bottom: 20px;
        }
    }
    #footer-nav {
        background-color: $bg-nav-footer;
        color: $text-nav-footer;
        border-top: 1px solid #999;
        // @media (max-width: 767px) {
        //     margin-bottom: $nav-mobile-height !important;
        // }
        .container {
            display: flex;
            align-items: center;
            @media (max-width: 991px) {
                flex-direction: column;
            }
            @media (max-width: 575px) {
                flex-direction: column;
            }
            > div {
                width: 33%;
                @media (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 15px;
                    text-align: center !important;
                }
                &.copyright-block {
                    font-size: 14px;
                    text-align: center;
                    @media (max-width: 991px) {
                        order: 2;
                        margin-bottom: 0;
                    }
                    .madeby-link {
                        color: $text-nav-footer;
                        text-decoration: none;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
                &.payment-block {
                    text-align: left;
                    @media (max-width: 991px) {
                        order: 0;
                    }
                    i {
                        display: inline-block;
                        font-size: 40px;
                        margin: 0 15px;
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
                &.madeby-block {
                    font-size: 12px;
                    text-align: right;
                    a {
                        color: $text-body;
                        text-decoration: none;
                        &:hover {
                            color: $primary-color;
                            text-decoration: none;
                        }
                    }
                }
                &.copyright-block,
                &.payment-block,
                &.madeby-block {
                    @media (max-width: 575px) {
                        width: 100%;
                        text-align: center;
                        font-size: 16px;
                        margin: 10px 0;
                    }
                }
                &.social-links {
                    text-align: right;
                    .social-sharing {
                        justify-content: flex-end;
                        align-items: center;
                        @media (max-width: 767px) {
                            justify-content: center;
                        }
                        .social-share-btn {
                            margin: 0 0 0 15px;
                            background-color: unset;
                            color: $text-nav-footer;
                        }
                    }
                }
            }
        }
    }
    #footer-nav-mobile {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: $nav-mobile-height;
        width: 100%;
        background-color: $bg-nav-footer-mobile;
        color: $text-nav-footer-mobile;
        .user-info {
            // Module ps_customersignin
            .dropdown-menu {
                transform: none !important;
                position: fixed !important;
                top: auto !important;
                left: 0 !important;
                right: 0 !important;
                bottom: $nav-mobile-height !important;
                padding: .5rem 15%;
                border: none;
                border-top: 1px solid $dark-color;
                li {
                    margin: 7px 0;
                    img {
                        margin-right: 15px;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
