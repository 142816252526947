// #region Roboto
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'),
    local('Roboto-Regular'),
    url(./webxy/fonts/roboto/Roboto-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src: local('Roboto'),
    local('Roboto-Italic'),
    url(./webxy/fonts/roboto/Roboto-Italic.ttf) format('truetype');
}

    // LIGHT
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto'),
    local('Roboto-Light'),
    url(./webxy/fonts/roboto/Roboto-Light.ttf) format('truetype');
}
    // LIGHT ITALIC
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src: local('Roboto'),
    local('Roboto-LightItalic'),
    url(./webxy/fonts/roboto/Roboto-LightItalic.ttf) format('truetype');
}

    // MEDIUM
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto'),
    local('Roboto-Medium'),
    url(./webxy/fonts/roboto/Roboto-Medium.ttf) format('truetype');
}
    // MEDIUM ITALIC
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src: local('Roboto'),
    local('Roboto-MediumItalic'),
    url(./webxy/fonts/roboto/Roboto-MediumItalic.ttf) format('truetype');
}

    // BOLD
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: local('Roboto'),
    local('Roboto-Bold'),
    url(./webxy/fonts/roboto/Roboto-Bold.ttf) format('truetype');
}
    // BOLD ITALIC
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src: local('Roboto'),
    local('Roboto-BoldItalic'),
    url(./webxy/fonts/roboto/Roboto-BoldItalic.ttf) format('truetype');
}
    // EXTRA BOLD
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src: local('Roboto'),
    local('Roboto-Black'),
    url(./webxy/fonts/roboto/Roboto-Black.ttf) format('truetype');
}
    // EXTRA BOLD ITALIC
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src: local('Roboto'),
    local('Roboto-BlackItalic'),
    url(./webxy/fonts/roboto/Roboto-BlackItalic.ttf) format('truetype');
}
// #endregion ------------

// #region Icones specifiques
@font-face {
    font-family: 'Webxy-icons';
    src:  url(./webxy/fonts/icons/webxy-icons.eot?t8dyx0);
    src:  url(./webxy/fonts/icons/webxy-icons.eot?t8dyx0#iefix) format('embedded-opentype'),
    url(./webxy/fonts/icons/webxy-icons.ttf?t8dyx0) format('truetype'),
    url(./webxy/fonts/icons/webxy-icons.woff?t8dyx0) format('woff'),
    url(./webxy/fonts/icons/webxy-icons.svg?t8dyx0#webxy-icons) format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@import "./fonts/icons/style.scss";
// #endregion ---------------
