#header {
    .menu {
        margin-top: -50px;
        margin-left: 357px; // = .header-top__col width with margins
        padding-bottom: 0;
        flex: 1;
        transition: margin-top .1s ease-out, margin-left .3s ease-in;
        @media (max-width: 1199px) {
            margin-top: 0;
            margin-left: 0;
        }
        a {
            text-decoration: none !important;
        }
        .menu-top {
            position: static;
            justify-content: flex-start;
            > li {
                &[aria-haspopup="true"] {
                    &.menu__item--active {
                        .menu-sub {
                            display: block;
                        }
                        > .menu__item-header {
                            position: relative;
                            &:after {
                                content: "";
                                z-index: 5;
                                position: absolute;
                                left: 50%;
                                transform: translateX(-50%);
                                width: 0;
                                height: 0;
                                border-bottom: solid 15px #FFF;
                                border-left: solid 15px transparent;
                                border-right: solid 15px transparent;
                                pointer-events: none;
                            }
                        }
                    }
                }
            }
            .menu__item--top {
                &[aria-haspopup="true"] {
                    .menu__item-link--top {
                        > span {
                            &:after {
                                margin-left: 4px;
                                font-size: 18px;
                                line-height: 25px;
                                color: #000;
                                content: 'arrow_drop_down';
                                @extend .material-icons;
                            }
                        }
                    }
                }
                .menu__item-link--top,
                .menu__item-link--top > a {
                    font-size: 18px;
                    font-weight: 500;
                    color: $text-menu-link-1;
                    padding: 13px 26px;
                    &:first-child {
                        padding-left: 0;
                    }
                    // &:last-child {
                    //     padding-right: 0;
                    // }
                    @media (max-width: $container-width-desktop-up) {
                        font-size: 17px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    @media (max-width: 1199px) {
                        padding-left: 20px;
                        padding-right: 20px;
                        padding-bottom: 7px;
                    }
                }
            }
            .menu__item-link--sub {
                color: $text-body;
                &:hover {
                    color: $text-body-darker;
                }
            }
            .menu-sub {
                margin: 0 auto;
                // top: calc(100% + 15px);
                max-width: 1140px;
                max-width: calc(1140px - 30px);
                left: 0;
                right: 0;
                display: none;
                @media (min-width: #{$container-width-desktop-up}) {
                    max-width: #{$container-width-desktop};
                    max-width: calc(#{$container-width-desktop} - 30px);
                }
                .menu-sub__content {
                    > ul {
                        box-shadow: none;
                        border: 1px solid $grey-color;
                        border-top-width: 0;
                    }
                    .menu-sub__list {
                        // border-radius: 5px;
                        &[data-depth="1"] {
                            flex-wrap: wrap;
                            > li {
                                width: 25%;
                            }
                        }
                        .menu__item-link--sub {
                            &[data-depth="1"] {
                                color: $text-menu-link-2;
                                font-size: 15px;
                                padding-bottom: 5px;
                                border-bottom: 1px solid $grey-color;
                                margin-bottom: 5px;
                            }
                            &[data-depth="2"] {
                                font-size: 14px;
                                margin-top: 5px;
                                &:before {
                                    @extend .material-icons;
                                    content: "\e5cc";
                                    font-size: 13px;
                                    line-height: 19px;
                                    color: $grey-color-alt;
                                }
                                &.menu__item-link--hassubmenu {
                                    &:before {
                                        content: "\e313";
                                    }
                                }
                            }
                            &[data-depth="3"] {
                                font-size: 12px;
                                margin-left: 25px;
                                &:before {
                                    content: "-";
                                    margin-right: 5px;
                                    color: $grey-color-alt;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
#mobile_top_menu_wrapper {
    .close {
        color: $error-color;
        opacity: 1;
        font-size: 44px;
        padding: 10px;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    #top-menu {
        > li {
            > .menu__item-header, > a {
                padding: $margin-s;
            }
        }
    }
    .language-selector {
        img {
            width: 32px;
            height: 32px;
            margin-right: 20px;
            vertical-align: bottom;
        }
        a {
            font-size: 22px;
            &.active {
                color: $grey-color;
            }
        }
    }
    .js-top-menu-bottom {
        #_mobile_language_selector {
            #language-selector-label {
                display: none !important;
            }
            button {
                display: block;
                width: 100%;
                font-size: 18px;
            }
            .dropdown-menu {
                width: 100%;
                .dropdown-item {
                    &.active {
                        color: $light-color;
                    }
                }
            }
        }
        #_mobile_contact_link {
            padding: 8px 22px;
            text-align: center;
            a {
                font-size: 18px;
                i {
                    vertical-align: middle;
                }
            }
        }
    }
    .menu-sub__content {
        .menu-sub__list {
            a {
                &[data-depth="1"] {
                    margin-left: 0;
                }
            }
        }
    }
}
