@import "variables";

[class^="xyicon-"], [class*=" xyicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Webxy-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.xyicon-check-on {
  &:before {
    content: $xyicon-check-on;
  }
}
.xyicon-heart {
  &:before {
    content: $xyicon-heart;
  }
}
.xyicon-linkedin-c {
  &:before {
    content: $xyicon-linkedin-c;
  }
}
.xyicon-pinterest-c {
  &:before {
    content: $xyicon-pinterest-c;
  }
}
.xyicon-twitter-c {
  &:before {
    content: $xyicon-twitter-c;
  }
}
.xyicon-eye-close {
  &:before {
    content: $xyicon-eye-close;
  }
}
.xyicon-tools {
  &:before {
    content: $xyicon-tools;
  }
}
.xyicon-categories {
  &:before {
    content: $xyicon-categories;
  }
}
.xyicon-eye {
  &:before {
    content: $xyicon-eye;
  }
}
.xyicon-facebook-c {
  &:before {
    content: $xyicon-facebook-c;
  }
}
.xyicon-pinterest {
  &:before {
    content: $xyicon-pinterest;
  }
}
.xyicon-cart {
  &:before {
    content: $xyicon-cart;
  }
}
.xyicon-trash {
  &:before {
    content: $xyicon-trash;
  }
}
.xyicon-chating {
  &:before {
    content: $xyicon-chating;
  }
}
.xyicon-send-o {
  &:before {
    content: $xyicon-send-o;
  }
}
.xyicon-trash-o {
  &:before {
    content: $xyicon-trash-o;
  }
}
.xyicon-minus {
  &:before {
    content: $xyicon-minus;
  }
}
.xyicon-plus {
  &:before {
    content: $xyicon-plus;
  }
}
.xyicon-home-o {
  &:before {
    content: $xyicon-home-o;
  }
}
.xyicon-translation {
  &:before {
    content: $xyicon-translation;
  }
}
.xyicon-linkedin {
  &:before {
    content: $xyicon-linkedin;
  }
}
.xyicon-phone {
  &:before {
    content: $xyicon-phone;
  }
}
.xyicon-elipsis-v {
  &:before {
    content: $xyicon-elipsis-v;
  }
}
.xyicon-newsletter {
  &:before {
    content: $xyicon-newsletter;
  }
}
.xyicon-phone-o {
  &:before {
    content: $xyicon-phone-o;
  }
}
.xyicon-newsletter-o {
  &:before {
    content: $xyicon-newsletter-o;
  }
}
.xyicon-youtube {
  &:before {
    content: $xyicon-youtube;
  }
}
.xyicon-cheque {
  &:before {
    content: $xyicon-cheque;
  }
}
.xyicon-burger {
  &:before {
    content: $xyicon-burger;
  }
}
.xyicon-instagram {
  &:before {
    content: $xyicon-instagram;
  }
}
.xyicon-twitter {
  &:before {
    content: $xyicon-twitter;
  }
}
.xyicon-facebook {
  &:before {
    content: $xyicon-facebook;
  }
}
.xyicon-close {
  &:before {
    content: $xyicon-close;
  }
}
.xyicon-user {
  &:before {
    content: $xyicon-user;
  }
}
.xyicon-search {
  &:before {
    content: $xyicon-search;
  }
}
.xyicon-timer {
  &:before {
    content: $xyicon-timer;
  }
}
.xyicon-bag {
  &:before {
    content: $xyicon-bag;
  }
}
.xyicon-paypal {
  &:before {
    content: $xyicon-paypal;
  }
}
.xyicon-payment-user {
  &:before {
    content: $xyicon-payment-user;
  }
}
.xyicon-visa {
  &:before {
    content: $xyicon-visa;
  }
}
.xyicon-mastercard {
  &:before {
    content: $xyicon-mastercard;
  }
}
.xyicon-cb-o {
  &:before {
    content: $xyicon-cb-o;
  }
}
.xyicon-cb {
  &:before {
    content: $xyicon-cb;
  }
}
.xyicon-caret-up {
  &:before {
    content: $xyicon-caret-up;
  }
}
.xyicon-caret-right {
  &:before {
    content: $xyicon-caret-right;
  }
}
.xyicon-caret-left {
  &:before {
    content: $xyicon-caret-left;
  }
}
.xyicon-caret-down {
  &:before {
    content: $xyicon-caret-down;
  }
}
.xyicon-arrow_left {
  &:before {
    content: $xyicon-arrow_left;
  }
}
.xyicon-arrow_top {
  &:before {
    content: $xyicon-arrow_top;
  }
}
.xyicon-arrow_right {
  &:before {
    content: $xyicon-arrow_right;
  }
}
.xyicon-arrow_down {
  &:before {
    content: $xyicon-arrow_down;
  }
}
.xyicon-pin {
  &:before {
    content: $xyicon-pin;
  }
}

