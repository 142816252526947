#category {
    .wrapper-container {
        padding-top: 0;
    }
    // Page catégorie uniquement
    .breadcrumb {
        margin: 0;
        @media (max-width: 767px) {
            justify-content: center;
        }
        li {
            a {
                text-decoration: none !important;
            }
        }
    }
    #left-column {
        > div {
            margin-bottom: $margin-m;
        }
        .block-categories {
            border-top-width: 1px;
        }
    }
}
#category,
#search {
    .pagination {
        flex-direction: column;
        margin-top: $margin-l;
        .pagination-progress {
            width: 100%;
            height: 1px;
            background: #CCC;
            margin-top: 15px;
            .pagination-completion {
                background: $primary-color;
                height: 100%;
                width: 0;
            }
        }
        .pagination-summary,
        .pagination-nav {
            width: 230px;
        }
        .pagination-summary {
            margin: 0 auto 30px auto;
        }
        .pagination-nav {
            margin: 0 auto;
            a[rel="next"] {
                font-size: 15px;
                font-weight: normal;
                display: block;
            }
            .back-to-top {
                font-size: 12px;
                font-weight: normal;
                color: $primary-color;
            }
        }
    }
}
#js-product-list-top {
    display: flex;
    margin-bottom: $margin-m;
    @media (max-width: 991px) {
        flex-wrap: wrap;
    }
    .total-products {
        flex-grow: 4;
        text-align: right;
    }
    .ordering {
        flex-grow: 1;
        flex-shrink: 0;
        label[for="select-sort-order"] {
            margin-right: 30px;
        }
    }
    #search_filter_toggler {
        border: none;
    }
}
#js-active-search-filters {
    background-color: $light-color-alt;
    .active-filter__title {
        font-size: 18px;
        font-weight: normal;
    }
}
.block-category {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin-bottom: $margin-l;
    border: none;
    border-bottom: 1px solid $grey-color-alt;
    #_desktop_category_header {
        display: flex;
        flex-wrap: nowrap;
        margin: 30px 0 50px;
        align-items: center;
        .category-heading-logo {
            width: 35px;
            height: 27px;
            background-image: url('../img/webxy/heading-page.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }
        .category-header-information {
            display: flex;
            flex-direction: column;
            padding-left: 12px;
            h1 {
                margin-bottom: 0;
                line-height: 1;
                font-weight: bold;
            }
        }
    }
    .category-cover {
        position: relative;
        &:before {
          display: block;
          content: "";
          width: 100%;
          padding-top: (9 / 35) * 100%;
        }
        > .cover-picture {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          @media (max-width: 767px) {
              display: none;
          }
        }
        @media (max-width: 991px) {
            width: 100%;
        }
    }
    #subcategories {
        margin-bottom: 60px;
        padding: 0 50px;
        .subcategory-card {
            text-align: center;
            text-transform: uppercase;
            padding: 0 15px;
            > div {
                border: 1px solid $grey-color-alt;
                transition: all .3s ease-in;
                &:hover {
                    border-color: $grey-color;
                }
            }
            img {
                height: 140px;
                margin: 0 auto;
                max-width: 100%;
            }
            .subcategory-name {
                font-size: 14px;
                line-height: 1.03;
                font-weight: 500;
                color: $primary-color;
                display: inline-block;
                padding: 15px 5px;
                height: 54px;
            }
        }
        .slick-prev,
        .slick-next {
            pointer-events: all;
            color: $grey-color;
            opacity: 1;
            border: none !important;
            outline: none !important;
            background-color: transparent !important;
            // top: -100px;
            // left: auto;
            // height: auto;
            transition: all .3s ease-in-out;
            i {
                background-color: transparent;
            }
        }
        .slick-prev {
            // right: 65px;
            left: -50px;
            &:hover {
                color: $dark-grey-color;
            }
        }
        .slick-next {
            right: -50px;
            &:hover {
                color: $dark-grey-color;
            }
        }
    }
}
.left-column, #_mobile_search_filters_wrapper {
    .category-card {
        padding: 30px;
    }
    .category-top-menu {
        // Module ps_categorytree
        margin-bottom: 0;
        > li {
            &:first-child {
                margin-bottom: 15px;
                > a {
                    font-size: 22px;
                }
            }
        }
        a {
            font-size: 14px;
            line-height: 26px;
            color: $text-body;
        }
        .category-sub__item--0 {
            border-bottom: none;
        }
    }
    #search_filters {
        // Module ps_facetedsearch
        .facets-title {
            font-size: 22px;
            line-height: 26px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        .facet__header {
            margin-bottom: 15px;
        }
        .custom-control {
            margin: 10px 0;
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
        .ui-slider {
            .ui-widget-header {
                background-color: #7991A9;
            }
            .ui-slider-handle {
                width: 15px;
                height: 15px;
                top: -4px;
                border-radius: 50%;
                border-color: $dark-color;
                .slider-range-value {
                    position: absolute;
                    font-size: .625rem;
                    color: #333;
                    top: 18px;
                    background-color: #fff;
                }
                &.ui-state-focus {
                    z-index: 5;
                }
                &:first-of-type {
                    .slider-range-value {
                        left: -5px;
                    }
                }
                &:last-of-type {
                    .slider-range-value {
                        right: -5px;
                    }
                }
            }
        }
    }
    #category-information {
        background-color: $primary-color;
        margin-top: 75px;
        padding: 10px 15px;
        font-size: 13px;
        line-height: 20px;
        border-radius: $round-radius;
        @media (max-width: 991px) {
            margin-top: 0;
        }
        @media (max-width: 1199px) {
            margin-top: 30px;
        }
    }
}
#_mobile_search_filters_wrapper {
    #search_filters {
        padding: 0 15px;
        .facet__block {
            margin-top: $margin-s;
            padding-bottom: $margin-s;
        }
    }
}
.products {
    .product-miniature {
        color: $text-body;
        &:hover {
            .product-flag {
                opacity: 0 !important;
            }
        }
        .card-product {
            border: 1px solid $grey-color-alt;
            .card-img-top {
                &:hover {
                    .highlighted-informations {
                        opacity: 1;
                        &::before {
                            opacity: 0.6;
                        }
                    }
                }
                .highlighted-informations {
                    // Bloc aperçu rapide affiché lors du hover
                    position: absolute;
                    bottom: 0;
                    top: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: none;
                    transition: all .3s ease-in-out;
                    background: transparent;
                    opacity: 0;
                    @media (max-width: 767px) {
                        display: none;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        bottom: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-color: #fff;
                        opacity: 0;
                    }
                    .quick-view {
                        border: 1px solid $dark-color;
                        background-color: $light-color;
                        padding: 5px 10px;
                        font-weight: 500;
                        z-index: 1;
                    }
                }
            }
            .card-body {
                padding: 10px 10px 15px;
                .product__card-desc {
                    justify-content: unset;
                }
                @media (max-width: 767px) {
                    flex-direction: column;
                }
            }
        }
        .product-category {
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            text-transform: uppercase;
            color: #787EA4;
            overflow: hidden;
            @media (max-width: $container-width-desktop-up) {
                font-size: 11px;
            }
            @media (max-width: 767px) {
                height: 28px;
            }
        }
        .product-title {
            margin: 7px 0 8px;
            font-weight: 500;
            line-height: 18px;
            color: $primary-color;
            height: 36px; // = affichage sur 2 lignes max
            text-align: left;
            overflow: hidden;
            a {
                color: $pl-product-name-color !important;
                &:hover {
                    color: $pl-product-name-color-hover !important;
                    text-decoration: none;
                    border-bottom: none;
                }
            }
        }
        .product-reference {
            font-size: 13px;
            line-height: 15px;
            margin-bottom: 5px;
            .reference {
                font-weight: 500;
            }
        }
        .product-price-and-shipping {
            display: flex;
            align-items: center;
            margin-top: 12px;
            margin-bottom: 15px;
            justify-content: space-between;
            .product-price {
                font-size: 18px;
                line-height: 24px;
                color: $pl-price-color;
            }
            .regular-price {
                font-size: 13px;
                font-style: italic;
            }
            // Bouton "voir produit" (si le produit possède des déclinaisons)
            &+.btn {
                padding: 2px 10px;
            }
        }
        .add-cart-form {
            display: flex;
            .qty {
                flex: 0 0 68px;
                .bootstrap-touchspin {
                    box-shadow: none;
                    border: none;
                    .input-group-prepend {
                        border: 1px solid $grey-color-alt;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                        .btn {
                            padding-right: 0;
                        }
                    }
                    .input-group-append {
                        border: 1px solid $grey-color-alt;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                        .btn {
                            padding-left: 0;
                        }
                    }
                }
                .btn {
                    padding: 0px 5px;
                    color: $grey-color;
                }
            }
            .add-to-cart-container {
                flex: 1 1 auto;
                padding-left: 8px;
            }
            input[name="qty"] {
                height: 30px;
                max-width: 100%;
                font-weight: normal;
                padding: 8px 5px;
                border-top: 1px solid $grey-color-alt;
                border-bottom: 1px solid $grey-color-alt;
            }
            .btn-add-to-cart {
                display: flex;
                align-items: center;
                width: 100%;
                text-align: unset;
                padding: 2px 10px;
                font-size: 18px;
                line-height: 24px;
                @media (max-width: $container-width-desktop-up) {
                    justify-content: center;
                    padding: 4px 10px;
                }
                i {
                    flex: 0 0 auto;
                    font-size: 20px;
                    margin-right: 5px;
                    @media (max-width: $container-width-desktop-up) {
                        margin-right: 0;
                    }
                }
                span {
                    flex: 1 1 auto;
                    @media (max-width: $container-width-desktop-up) {
                        display: none;
                    }
                }
            }
        }
        .product-flags {
            pointer-events: none;
            .product-flag {
                font-size: 10px;
                font-weight: 500;
                text-transform: none;
                opacity: 1;
                transition: all .3s ease-in;
                min-width: 60px;
                text-align: center;
                &.discount-product {
                    background-color: $discount-color;
                }
            }
        }
        .quantity-wanted {
            /* Firefox */
            -moz-appearance: textfield;
            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }
    }
    &[data-slick] {
        .product-flags {
            left: 20px;
            top: 12px;
            .product-flag {
                font-size: 14px;
                font-weight: bold;
                padding: 7px;
                min-width: 100px;
                text-align: center;
                &.discount-product {
                    @extend .badge-danger;
                }
            }
            @media (max-width: $container-width-desktop-up) {
                top: 0;
                left: 0;
                .product-flag {
                    padding: 1px 7px;
                    min-width: 80px;
                }
            }
        }
        // Listes produits avec slider Slick
        &:hover {
            .slick-prev, .slick-next {
                opacity: 0.3;
                pointer-events: all;
                color: $arrow-colors-hover;
            }
        }
        .slick-prev, .slick-next {
            opacity: 0;
            pointer-events: none;
            color: $arrow-colors;
            border: none !important;
            outline: none !important;
            background-color: transparent !important;
            transition: all .3s ease-in-out;
            i {
                background-color: transparent;
            }
        }
        .slick-prev {
            left: -30px;
        }
        .slick-next {
            right: -30px;
        }
    }
}

.featured-products,
.xy-new-products,
.xy-discounted-products,
.product-accessories {
    .heading-page {
        margin-bottom: 36px;
    }
    .products-slick {
        &:hover {
            .slick-prev,
            .slick-next {
                opacity: 1;
                color: $grey-color;
            }
        }
        .slick-prev,
        .slick-next {
            pointer-events: all;
            color: $grey-color;
            opacity: 1;
            @media (max-width: 575px) {
                top: -60px !important;
            }
        }
        .slick-prev {
            // top: -70px;
            // right: 65px;
            // left: auto;
            // height: auto;
            &:hover {
                color: $dark-grey-color;
            }
        }
        .slick-next {
            // top: -70px;
            // right: 0;
            // left: auto;
            // height: auto;
            &:hover {
                color: $dark-grey-color;
            }
        }
        @media (max-width: 1199px) {
            .slick-prev {
                right: 45px;
            }
        }
    }
}

.slick-inside-arrows {
    padding: 0 50px;
}

// Nombre de produits par lignes (@see _dev\css\components\products.scss)
@include media-breakpoint-desktop {
    .product-miniature{
        flex: 0 0 25%;
        max-width: 25%;
    }

    .layout-full-width .product-miniature{
        flex: 0 0 25%;
        max-width: 25%;
    }
}
