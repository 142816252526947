$xyicon-check-on: "\e900";
$xyicon-heart: "\e901";
$xyicon-linkedin-c: "\e902";
$xyicon-pinterest-c: "\e903";
$xyicon-twitter-c: "\e904";
$xyicon-eye-close: "\e905";
$xyicon-tools: "\e906";
$xyicon-categories: "\e907";
$xyicon-eye: "\e908";
$xyicon-facebook-c: "\e909";
$xyicon-pinterest: "\e90a";
$xyicon-cart: "\e90b";
$xyicon-trash: "\e90c";
$xyicon-chating: "\e90d";
$xyicon-send-o: "\e90e";
$xyicon-trash-o: "\e90f";
$xyicon-minus: "\e910";
$xyicon-plus: "\e911";
$xyicon-home-o: "\e912";
$xyicon-translation: "\e913";
$xyicon-linkedin: "\e914";
$xyicon-phone: "\e915";
$xyicon-elipsis-v: "\e916";
$xyicon-newsletter: "\e917";
$xyicon-phone-o: "\e918";
$xyicon-newsletter-o: "\e919";
$xyicon-youtube: "\e91a";
$xyicon-cheque: "\e91b";
$xyicon-burger: "\e91c";
$xyicon-instagram: "\e91d";
$xyicon-twitter: "\e91e";
$xyicon-facebook: "\e91f";
$xyicon-close: "\e920";
$xyicon-user: "\e921";
$xyicon-search: "\e922";
$xyicon-timer: "\e923";
$xyicon-bag: "\e924";
$xyicon-paypal: "\e925";
$xyicon-payment-user: "\e926";
$xyicon-visa: "\e927";
$xyicon-mastercard: "\e928";
$xyicon-cb-o: "\e929";
$xyicon-cb: "\e92a";
$xyicon-caret-up: "\e92b";
$xyicon-caret-right: "\e92c";
$xyicon-caret-left: "\e92d";
$xyicon-caret-down: "\e92e";
$xyicon-arrow_left: "\e92f";
$xyicon-arrow_top: "\e930";
$xyicon-arrow_right: "\e931";
$xyicon-arrow_down: "\e932";
$xyicon-pin: "\e947";

