.checkout {
    bottom: $nav-mobile-height;
}

.modal-cart__name {
    color: $primary-color;
}

#checkout {
    #header {
        border: none;
    }
    .header-nav {
        padding: 0 20px 0 0;
    }
    #content {
        margin-top: $margin-m;
    }
}

#cart, #checkout {
    .product-line-grid-right {
        .bootstrap-touchspin {
            max-width: 160px;
        }
        @media(max-width: 399px) {
            flex-wrap: wrap;
            justify-content: center;
        }
        > div:first-child {
            margin-bottom: 20px;
        }
    }
    .js-cart-line-product-quantity {
        /* Firefox */
        -moz-appearance: textfield;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    .delivery-option {
        background-color: $light-color-alt;
    }
    .carrier-price {
        color: $primary-color;
    }
    #footer-nav {
        // margin-bottom: 130px !important;
        // margin-bottom: calc(#{$nav-mobile-height} + 80px);
    }
}

#order-confirmation {
    #content-hook_order_confirmation {
        .card-title {
            .material-icons {
                font-size: 48px;
                margin-right: 15px;
            }
        }
        &.bg-success {
            a {
                color: $light-color;
                text-decoration: underline;
                &:hover {
                    color: $light-color;
                    text-decoration: none;
                }
            }
        }
    }
    #order-items {
        .table__title-head {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}
