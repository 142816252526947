// Etiquettes "-Economisez X%" sur les fiches produits
.discount {
    background-color: $discount-color;
    color: $light-color;
}
.price {
    // Prix initial barré
    &.current-price-discount {
        color: $dark-color;
    }
}
