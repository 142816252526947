body {
    &.scrolling {
        #header {
            .header-top {
                .header-top__col {
                    @media (min-width: 1200px) {
                        width: 150px;
                    }
                }
            }
            .menu {
                margin-top: 0;
                margin-left: 0;
            }
        }
    }
    &.has-banner {
        #header {
            top: -70px;
        }
    }
    @media (max-width: 767px) {
        #header {
            top: 0 !important;
        }
    }
    #header {
        background-color: $bg-top;
        margin-bottom: 30px;
        padding-bottom: 10px;
        color: $text-top;
        position: sticky;
        top: 0;
        z-index: 5;
        box-shadow: none;
        border-bottom: 1px solid $grey-color;
        a {
            color: $text-header-links;
            font-size: 17px;
            font-weight: normal;
            text-transform: none;
        }
        .header-nav {
            background-color: $bg-nav;
            color: $text-nav;
            font-size: 16px;
            font-weight: 700;
            a, .btn {
                color: $text-nav;
                font-size: 16px;
                font-weight: 700;
                text-transform: none;
                @media (max-width: 479px) {
                    font-size: 14px;
                }
            }
            .header-nav-content {
                position: relative;
                padding: 4px 0 4px;
                display: flex;
                @media (max-width: 991px) {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                > * {
                    flex-shrink: 0;
                    padding: 0 40px;
                    i {
                        color: $secondary-color;
                        font-size: 18px;
                        vertical-align: middle;
                    }
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
                @media (max-width: 575px) {
                    position: relative;
                }
            }
            #_desktop_contact_link {
                // Module ps_contactinfo
                @media (max-width: 991px) {
                    display: none;
                }
                i[class^="xyicon-"] {
                    font-size: 19px;
                    line-height: 20px;
                    vertical-align: middle;
                    margin-right: 7px;
                }
            }
            #_desktop_phone_link {
                // Module ps_contactinfo
                font-weight: 700;
                i[class^="xyicon-"] {
                    font-size: 18px;
                    vertical-align: sub;
                    margin-right: 7px;
                }
                @media (max-width: 991px) {
                    display: none;
                }
            }
            #_desktop_language_selector {
                // Module ps_languageselector
                @media (max-width: 991px) {
                    display: none;
                }
                *[data-toggle="dropdown"] {
                    font-size: 13px;
                    &:focus {
                        box-shadow: none;
                        border: 1px solid $light-color;
                        border-radius: $round-radius;
                    }
                }
                .dropdown-menu {
                    padding: 0;
                    margin-top: 10px;
                    .dropdown-item {
                        padding: 7px 22px;
                        color: $body-color;
                        &.active {
                            color: $text-dropdown-active-item;
                        }
                    }
                }
            }
            .our-warehouse-nav-container {
                // Module xyheaderbutton (voir BO)
                @media (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
            }
            .quote-link {
                // Module xyheaderbutton (voir BO)
                @media (max-width: 575px) {
                    flex: 1 1;
                }
                a {
                    background: $light-color;
                    color: $bg-nav;
                    font-size: 18px;
                    border-radius: 4px;
                    font-weight: 500;
                    @media (max-width: 1199px) {
                        font-size: 16px;
                    }
                    @media (max-width: 767px) {
                        font-size: 12px;
                        padding: 8px 10px;
                    }
                    @media (max-width: 575px) {
                        display: block;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            background-color: $bg-nav-mobile;
            color: $text-nav-mobile;
        }
        .header__logo-mobile {
            width: 75px;
            @media (max-width: 575px) {
                margin-left: 10px;
            }
        }
        #site-tagline {
            display: none;
            order: 1;
            font-size: 16px;
            @media (max-width: 767px) {
                display: inline-block;
            }
            @media (max-width: 575px) {
                font-size: 12px;
            }
        }
        #menu-icon {
            color: $text-nav;
            .material-icons {
                font-size: 38px;
            }
            @media (max-width: 991px) {
                padding: 0 15px;
            }
            @media (max-width: 767px) {
                order: 0;
            }
            @media (max-width: 575px) {
                padding: 0 10px 0 5px;
            }
            @media (max-width: 399px) {
                position: absolute;
                top: 10px;
            }
        }
        .header-top {
            flex-wrap: nowrap;
            padding: 0 15px;
            margin-top: 5px;
            align-items: flex-start;
            @media (max-width: 767px) {
                padding: 5px;
                flex-wrap: wrap;
                position: relative;
            }
            @media (max-width: 479px) {
                margin-top: 0;
            }
            .header__search {
                position: absolute;
                @media (max-width: 767px) {
                    display: none;
                }
            }
            .header-top__col {
                width: 285px; // Logo width
                margin-right: 72px;
                flex-shrink: 0;
                transition: all .3s ease-out;
                @media (max-width: 1199px) {
                    width: 125px !important;
                }
                @media (max-width: 991px) {
                    margin-right: 25px;
                }
                @media (max-width: 767px) {
                    order: -1;
                    width: 100% !important;
                    padding: 0 100px;
                    margin-right: 0;
                }
                > a {
                    @media (max-width: 767px) {
                        display: block;
                        width: 230px;
                        max-width: unset;
                        margin: 0 auto 15px;
                    }
                    @media (max-width: 479px) {
                        width: 180px;
                    }
                }
            }
            .header__right {
                width: auto;
                flex: 1;
                justify-content: space-between;
                @media (max-width: 991px) {
                    // padding: 7px 0;
                    // justify-content: flex-end;
                }
                .header__rightitem {
                    &:not(:last-child) {
                        margin: 0;
                    }
                }
                > * {
                    padding: 0;
                }
                .user-info {
                    // Module ps_customersignin
                    .account {
                        display: flex;
                        align-items: center;
                    }
                    img {
                        margin-right: 15px;
                        @media (max-width: 991px) {
                            margin-right: 7px;
                        }
                        @media (max-width: 575px) {
                            margin-right: 0;
                        }
                    }
                    i {
                        font-size: 26px;
                        color: $primary-color;
                        margin-right: 6px;
                    }
                    .visible--desktop {
                        font-size: 18px;
                        line-height: 1em;
                        font-weight: 300;
                        display: flex;
                        flex-direction: column;
                        @media (max-width: 1199px) {
                            display: none;
                        }
                    }
                }
                .search-widget {
                    display: none;
                }
            }
        }
        .logo {
            transition: all .5s ease-in-out;
        }
    }
}
