/*** FONTS ***/
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$custom-font:                 'Roboto'; // @see _font-custom.scss
$custom-font2:                'Roboto'; // @see _font-custom.scss
$custom-iconfont:             'Webxy-icons'; // @see _font-custom.scss
/*************/

/*** Structure de pages ***/
$container-width-desktop: 1400px;    // Si > taille standard de 1140px, prévoir une média querie dans le fichier _all.scss pour les écrans entre 992px et $container-width-desktop
$container-width-desktop-up: 1480px; // Utiliser pour les media queries
/**************************/

/*** COLORS ***/
$dark-color:            #232323;
$light-grey-color:      #EEEEEE;
$dark-grey-color:       #444444;
$grey-color:            #999999;
$grey-color-alt:        #DADAD9;
$light-color:           #FFF;
$light-color-alt:       #F8F8F8;
$primary-color:         #0A98D6;
$primary-color-alt:     #3fa7d3;
$secondary-color:       #838F9D;
$secondary-color-alt:   #6d7781;
$tertiary-color:        #3D4D52;
$tertiary-color-alt:    #27373b;
$error-color:           #CD1619;
$error-color-alt:       #d33e41;
$success-color:         #62AC72;
$success-color-alt:     #438852;
$warning-color:         #F6B93B;
$warning-color-alt:     #F9D48A;
$info-color:            #17a2b8;
$info-color-alt:        #78d3e0;
$discount-color:        #EC4345;
$link-color:            $primary-color;
$link-color-hover:      $grey-color;
$bg-body:               #FFF;
/*************/

/*** MARGINS ***/
$margin-xl: 80px;
$margin-l:  60px;
$margin-m:  30px;
$margin-s:  15px;

/*** TYPO ***/
$text-body:         $dark-color;
$text-body-darker:  #000;
/************/

/*** HEADER ***/
$bg-nav:            $light-color;
$text-nav:          $dark-color; // OU $body-color en fonction du contrast
$bg-top:            #FFF;
$text-top:          $text-body;
$bg-nav-mobile:     #FFF;
$text-nav-mobile:   $text-body; // OU $body-color en fonction du contrast

/**************/

/*** FOOTER ***/
$bg-footer:      #111218;
$text-footer:      $light-color;
$bg-nav-footer:  $primary-color;
$text-nav-footer:  $light-color;
$text-title-footer: $light-color;
$bg-nav-footer-mobile: $dark-color;
$text-nav-footer-mobile: $light-color;
$nav-mobile-height: 55px;
/**************/

/*** CART ***/
$bg-cart: $grey-color;
/************/

/*** BUTTONS ***/
$btn-roundness: 0px;
/**************/

/*** CATEGORY ***/

/****************/

/*** PRODUCT-LIST ***/
$pl-product-name-color:        $primary-color;
$pl-product-name-color-hover:   $primary-color-alt;
$arrow-colors:                 #000;
$arrow-colors-hover:           #000;
$pl-price-color:               $primary-color;
/********************/

/*** PRODUCT ***/
$p-price-color:     #333;
/***************/

/*** OTHER ***/
$text-header-links:             #222;
$text-menu-link-1:              $primary-color;      // Couleur des liens de 1er niveau
$text-menu-link-2:              $primary-color; // Couleur des liens de 2eme niveau
$bg-dropdown-active-item:       $primary-color;
$text-dropdown-active-item:     $light-color;
$bg-dropdown-hover-item:        $grey-color-alt;
$text-dropdown-hover-item:      $text-body;
$round-radius: 4px;
/*************/
