/***
    Couleurs disponibles :
        - btn-primary & btn-outside-primary
        - btn-secondary & btn-outside-secondary
        - btn-tertiary & btn-outside-tertiary
        - btn-error & btn-outside-error
        - btn-warning & btn-outside-warning
        - btn-link
    Helpers :
        - btn-rounded (boutons légèrement arrondis)
        - btn-chevron-right (affiche un chevron à droite du bouton)
        - btn-cart (affiche une icone panier à droite du bouton)
***/

.btn {
    white-space: normal;
    font-weight: 500;
    font-size: 1.1rem;
    border-radius: $btn-roundness;
    transition: all .3s ease-in;
    transition-delay: .3s, 0;
    transition-property: background-color, all;
    padding: 8px 22px;
    &.btn-primary,
    &.btn-secondary,
    &.btn-tertiary,
    &.btn-success,
    &.btn-error,
    &.btn-warning {
        position: relative;
        overflow: hidden;
        &:before {
            content: "";
            width: 150%;
            height: 500%;
            position: absolute;
            top: 125%;
            left: -125%;
            transform: rotate(45deg);
            transition: all .3s ease-in;
        }
        &:hover {
            &:not(.disabled):not([disabled]) {
                &::before {
                    top: -500%;
                    left: 95%;
                }
            }
        }
        &.disabled,
        &[disabled] {
            cursor: not-allowed;
        }
    }
    &:active {
        box-shadow: none !important;
    }
    &.btn-primary {
        background-color: $primary-color;
        border-color: $primary-color;
        color: $light-color;
        &:before {
            background-color: $primary-color-alt;
        }
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $primary-color-alt;
            border-color: $primary-color-alt;
        }
    }
    &.btn-outline-primary {
        background-color: $light-color;
        border-color: $primary-color;
        color: $primary-color;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $light-color;
            border-color: $primary-color-alt;
            color: $primary-color-alt;
        }
    }
    &.btn-secondary {
        background-color: $secondary-color;
        border-color: $secondary-color;
        color: $light-color;
        &:before {
            background-color: $secondary-color-alt;
        }
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $secondary-color-alt;
            border-color: $secondary-color-alt;
        }
    }
    &.btn-outline-secondary {
        background-color: $light-color;
        border-color: $secondary-color;
        color: $secondary-color;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $light-color;
            border-color: $secondary-color-alt;
            color: $secondary-color-alt;
        }
    }
    &.btn-tertiary {
        background-color: $tertiary-color;
        border-color: $tertiary-color;
        color: $light-color;
        &:before {
            background-color: $tertiary-color-alt;
        }
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $tertiary-color-alt;
            border-color: $tertiary-color-alt;
        }
    }
    &.btn-outline-tertiary {
        background-color: $light-color;
        border-color: $tertiary-color;
        color: $tertiary-color;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $light-color;
            border-color: $tertiary-color-alt;
            color: $tertiary-color-alt;
        }
    }
    &.btn-success {
        background-color: $success-color;
        border-color: $success-color;
        color: $light-color;
        &:before {
            background-color: $success-color-alt;
        }
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $success-color-alt;
            border-color: $success-color-alt;
        }
    }
    &.btn-outline-success {
        background-color: $light-color;
        border-color: $success-color;
        color: $success-color;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $light-color;
            border-color: $success-color-alt;
            color: $success-color-alt;
        }
    }
    &.btn-error {
        background-color: $error-color;
        border-color: $error-color;
        color: $light-color;
        &:before {
            background-color: $error-color-alt;
        }
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $error-color-alt;
            border-color: $error-color-alt;
        }
    }
    &.btn-outline-error {
        background-color: $light-color;
        border-color: $error-color;
        color: $error-color;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $light-color;
            border-color: $error-color-alt;
            color: $error-color-alt;
        }
    }
    &.btn-warning {
        background-color: $warning-color;
        border-color: $warning-color;
        color: $light-color;
        &:before {
            background-color: $warning-color-alt;
        }
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $warning-color-alt;
            border-color: $warning-color-alt;
        }
    }
    &.btn-outline-warning {
        background-color: $light-color;
        border-color: $warning-color;
        color: $warning-color;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            background-color: $light-color;
            border-color: $warning-color-alt;
            color: $warning-color-alt;
        }
    }
    &.btn-link {
        color: $text-body;
        &:hover,
        &:focus,
        &:active,
        &:not(.disabled):active {
            color: $primary-color;
        }
    }
    &.btn-rounded {
        border-radius: 4px;
    }
    &.btn-chevron-right {
        &:after {
            content: "\e5cc";
            @extend .material-icons;
        }
    }
    &.btn-cart {
        &:after {
            content: "\e854";
            @extend .material-icons;
        }
    }
}
.btn-addtocart-big {
    > img {
        margin-right: 15px;
        vertical-align: bottom;
    }
}
